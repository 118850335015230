import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout"
import AboutUs from "../sections/AboutUs"

export default function aboutUsPage() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us</title>
      </Helmet>
      <Layout>
        <AboutUs />
      </Layout>
    </div>
  )
}
