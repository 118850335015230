import React from "react"
import { Link } from "gatsby"
import { Navbar } from "react-bootstrap"

import logo from "../assets/images/logo/logo.svg"

export default function AboutUs() {
  const currentYear = new Date().getFullYear()

  return (
    <div className="footer-content-container">
      <Navbar className="bg-dark d-flex justify-content-start w-100 p-0 m-0">
        <Navbar.Brand className="d-flex justify-content-center align-items-center ps-2">
          <img alt="" src={logo} width="45" height="45" />
          <Link to="/" className="logo-text">
            Folksmedia
          </Link>
        </Navbar.Brand>
      </Navbar>

      <div
        className="footer-content-sub-container justify-text"
        style={{ height: "100vh" }}
      >
        <h6 className="fw-bold">About Us</h6>
        <br />
        <br />
        <div>
          <p className="lh-lg">
            FolksMedia app is designed with one person in mind- you. Our mission
            is to provide the easiest way to connect you to the streaming
            content you love. FolksMedia app looks across all streaming apps to
            provide movies and shows recommendations personalized for you. And
            with one click you’ll be watching and controlling the experience
            right from your smartphone. But that’s just the beginning.
            FolksMedia app will connect you to a seamless shopping experience,
            whether directly from ads you see or from our FolksMedia App
            Marketplace.
          </p>
          <p className="lh-lg">
            With the FolksMedia app, you’re not the product. We’re embarking on
            this journey together, and we’ll share our success with you via
            rewards tokens that you can use in many ways in the app. We welcome
            you to join us.
          </p>{" "}
          <p className="lh-lg">
            FolksMedia is a collection of services provided by Adcuratio for
            folks everywhere. Adcuratio is a US-based developer of advertising
            technology, including the industry standard platform that enables
            addressable national ad-versioning with our TV network, MVPD,
            connected TV, and advertising partners.
          </p>
        </div>

        <p className="paragraph-text fw-bold mt-5 text-center primary-text-color">
          Folksmedia © {currentYear}
        </p>
      </div>
    </div>
  )
}
